import $ from 'jquery'

const currentTime = new Date()

const monthData = {
  1: 'jan',
  2: 'feb',
  3: 'mar',
  4: 'apr',
  5: 'may',
  6: 'june',
  7: 'july',
  8: 'aug',
  9: 'sep',
  10: 'oct',
  11: 'nov',
  12: 'dec',
  13: 'n-jan',
  14: 'n-feb',
  15: 'n-mar',
  16: 'n-apr',
  17: 'n-may',
  18: 'n-june',
  19: 'n-july',
  20: 'n-aug',
  21: 'n-sep',
  22: 'n-oct',
  23: 'n-nov',
  24: 'n-dec',
}

const monthDataStr = {
  'jan': 1,
  'feb': 2,
  'mar': 3,
  'apr': 4,
  'may': 5,
  'june': 6,
  'july': 7,
  'aug': 8,
  'sep': 9,
  'oct': 10,
  'nov': 11,
  'dec': 12,
  'n-jan': 13,
  'n-feb': 14,
  'n-mar': 15,
  'n-apr': 16,
  'n-may': 17,
  'n-june': 18,
  'n-july': 19,
  'n-aug': 20,
  'n-sep': 21,
};


const targetName = "#scroll-image"
const defaultSetMonth = 21
const adjustWidth = 7

var leftPosition = currentTime.getMonth() + 1
var rightPosition = currentTime.getMonth() + 1

var target
var inlinePlace

function getEventImageAddedWidth() {
  var imageWidths = getEventimageEachWidth()
  return {
    0: imageWidths[0],
    1: imageWidths[1],
    2: imageWidths[1] + imageWidths[2],
    3: imageWidths[1] + imageWidths[2] + imageWidths[3],
    4: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4],
    5: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5],
    6: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6],
    7: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7],
    8: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8],
    9: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9],
    10: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9] + imageWidths[10],
    11: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9] + imageWidths[10] + imageWidths[11],
    12: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9] + imageWidths[10] + imageWidths[11] + imageWidths[12],
    //2024
    13: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9] + imageWidths[10] + imageWidths[11] + imageWidths[12] + imageWidths[13],
    14: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9] + imageWidths[10] + imageWidths[11] + imageWidths[12] + imageWidths[13] + imageWidths[14],
    15: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9] + imageWidths[10] + imageWidths[11] + imageWidths[12] + imageWidths[13] + imageWidths[14] + imageWidths[15],
    16: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9] + imageWidths[10] + imageWidths[11] + imageWidths[12] + imageWidths[13] + imageWidths[14] + imageWidths[15] + imageWidths[16],
    17: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9] + imageWidths[10] + imageWidths[11] + imageWidths[12] + imageWidths[13] + imageWidths[14] + imageWidths[15] + imageWidths[16] + imageWidths[17],
    18: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9] + imageWidths[10] + imageWidths[11] + imageWidths[12] + imageWidths[13] + imageWidths[14] + imageWidths[15] + imageWidths[16] + imageWidths[17] + imageWidths[18],
    19: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9] + imageWidths[10] + imageWidths[11] + imageWidths[12] + imageWidths[13] + imageWidths[14] + imageWidths[15] + imageWidths[16] + imageWidths[17] + imageWidths[18] + imageWidths[19],
    20: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9] + imageWidths[10] + imageWidths[11] + imageWidths[12] + imageWidths[13] + imageWidths[14] + imageWidths[15] + imageWidths[16] + imageWidths[17] + imageWidths[18] + imageWidths[19] + imageWidths[20],
    21: imageWidths[1] + imageWidths[2] + imageWidths[3] + imageWidths[4] + imageWidths[5] + imageWidths[6] + imageWidths[7] + imageWidths[8] + imageWidths[9] + imageWidths[10] + imageWidths[11] + imageWidths[12] + imageWidths[13] + imageWidths[14] + imageWidths[15] + imageWidths[16] + imageWidths[17] + imageWidths[18] + imageWidths[19] + imageWidths[20] + imageWidths[20]
  };
}

function getEventimageEachWidth() {
  return {
    0: 0,
    1: Math.round($("#e-jan").width()) - adjustWidth,
    2: Math.round($("#e-feb").width()),
    3: Math.round($("#e-mar").width()),
    4: Math.round($("#e-apr").width()),
    5: Math.round($("#e-may").width()),
    6: Math.round($("#e-june").width()),
    7: Math.round($("#e-july").width()),
    8: Math.round($("#e-aug").width()),
    9: Math.round($("#e-sep").width()),
    10: Math.round($("#e-oct").width()),
    11: Math.round($("#e-nov").width()),
    12: Math.round($("#e-dec").width()),
    //2024
    13: Math.round($("#e-n-jan").width()),
    14: Math.round($("#e-n-feb").width()),
    15: Math.round($("#e-n-mar").width()),
    16: Math.round($("#e-n-apr").width()),
    17: Math.round($("#e-n-may").width()),
    18: Math.round($("#e-n-june").width()),
    19: Math.round($("#e-n-july").width()),
    20: Math.round($("#e-n-aug").width()),
    21: Math.round($("#e-n-sep").width()),
  };
}

function setChangePointer(month) {
  $('.news .month .point').remove()
  $('.news .month .' + month).append('<img class="point" src="/assets/images/news_point.png">')
}

function setMoveEventImageToRight() {
  if (leftPosition == rightPosition) {
    if (leftPosition == defaultSetMonth) {
      target = ($("#coming-soon"))
    } else {
      target = ($("#e-" + monthData[leftPosition]))
    }
    inlinePlace = "end"
  } else {
    target = ($("#e-" + monthData[leftPosition + 1]))
    inlinePlace = "start"
  }
  target[0].scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: inlinePlace,
  });
}

function setMoveEventImageToLeft() {
  if (leftPosition == rightPosition) {
    if (Math.abs(getEventImageAddedWidth()[leftPosition - 1] - $(targetName).scrollLeft()) <= 1) {
      target = ($("#e-" + monthData[leftPosition - 1]))
    } else {
      target = ($("#e-" + monthData[leftPosition]))
    }
    inlinePlace = "start"
  } else {
    if ($(targetName).scrollLeft() == getEventImageAddedWidth()[leftPosition - 1]) {
      target = ($("#e-" + monthData[leftPosition - 1]))
      inlinePlace = "start"
    } else {
      target = ($("#e-" + monthData[leftPosition]))
      inlinePlace = "start"
    }
  }
  target[0].scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: inlinePlace,
  });
}

// サイトアクセス時に当月を指定する
// 画像のロードが完了してから、newsの画像を動かす
$(window).on('load', function () {
    currentMonth()
if (!location.href.includes('#')) {
    $('body,html').animate({
      scrollTop: 0
    }, 0, 'swing');
  }
  $('.news .month .' + monthData[defaultSetMonth]).append('<img class="point" src="/assets/images/news_point.png">')
})

// スクロール矢印の表示非表示をする
// 初期画面で左端にいる場合、左向きのスクロール矢印を非表示にする
$(function () {
  if ($('.news #scroll-image').scrollLeft() == 0) {
    $('.news .left-arrow img').fadeOut("fast")
  }
})

// 画像位置を移動させ、三角のポインターを当てる
$('.news .month .clickable').on('click', (e) => {
  selectedMonth($(e.currentTarget).data("month"))
})

$('.news #scroll-image').bind("scroll", function() {
  // 左端にいる場合に左向きのスクロール矢印を非表示にする
  if ($(this).scrollLeft() == 0) {
    $('.news .left-arrow img').fadeOut("fast")
  } else if (
    // 右端にいる場合に右向きのスクロール矢印を非表示にする
    $(this).scrollLeft() + adjustWidth >= getEventImageAddedWidth()[defaultSetMonth] + $("#coming-soon").width() - $(window).width()) {
    $('.news .right-arrow img').fadeOut("fast")
  } else {
    // それ以外の場合は、スクロール矢印を表示する
    $('.news .left-arrow img').fadeIn("fast")
    $('.news .right-arrow img').fadeIn("fast")
  }
});

// スクロール時に現在の画像を取得する
$(targetName).scroll(function(e) {
  var leftX = $(targetName).scrollLeft();
  var rightX = window.innerWidth + leftX
  var imageWidths = getEventImageAddedWidth()
  switch (true) {
    case leftX >= imageWidths[21]:
      leftPosition = 22;
      break;
    case leftX >= imageWidths[20]:
      leftPosition = 21;
      break;
    case leftX >= imageWidths[19]:
      leftPosition = 20;
      break;
    case leftX >= imageWidths[18]:
      leftPosition = 19;
      break;
    case leftX >= imageWidths[17]:
      leftPosition = 18;
      break;
    case leftX >= imageWidths[16]:
      leftPosition = 17;
      break;
    case leftX >= imageWidths[15]:
      leftPosition = 16;
      break;
    case leftX >= imageWidths[14]:
      leftPosition = 15;
      break;
    case leftX >= imageWidths[13]:
      leftPosition = 14;
      break;
    case leftX >= imageWidths[12]:
      leftPosition = 13;
      break;
    case leftX >= imageWidths[11]:
      leftPosition = 12;
      break;
    case leftX >= imageWidths[10]:
      leftPosition = 11;
      break;
    case leftX >= imageWidths[9]:
      leftPosition = 10;
      break;
    case leftX >= imageWidths[8]:
      leftPosition = 9;
      break;
    case leftX >= imageWidths[7]:
      leftPosition = 8;
      break;
    case leftX >= imageWidths[6]:
      leftPosition = 7;
      break;
    case leftX >= imageWidths[5]:
      leftPosition = 6;
      break;
    case leftX >= imageWidths[4]:
      leftPosition = 5;
      break;
    case leftX >= imageWidths[3]:
      leftPosition = 4;
      break;
    case leftX >= imageWidths[2]:
      leftPosition = 3;
      break;
    case leftX >= imageWidths[1]:
      leftPosition = 2;
      break;
    case leftX > 0:
      leftPosition = 1;
      break;
  }
  switch (true) {
    case rightX < imageWidths[1]:
      rightPosition = 1;
      break;
    case rightX < imageWidths[2]:
      rightPosition = 2;
      break;
    case rightX < imageWidths[3]:
      rightPosition = 3;
      break;
    case rightX < imageWidths[4]:
      rightPosition = 4;
      break;
    case rightX < imageWidths[5]:
      rightPosition = 5;
      break;
    case rightX < imageWidths[6]:
      rightPosition = 6;
      break;
    case rightX < imageWidths[7]:
      rightPosition = 7;
      break;
    case rightX < imageWidths[8]:
      rightPosition = 8;
      break;
    case rightX < imageWidths[9]:
      rightPosition = 9;
      break;
    case rightX < imageWidths[10]:
      rightPosition = 10;
      break;
    case rightX < imageWidths[11]:
      rightPosition = 11;
      break;
    case rightX < imageWidths[12]:
      rightPosition = 12;
      break;
    case rightX < imageWidths[13]:
      rightPosition = 13;
      break;
    case rightX < imageWidths[14]:
      rightPosition = 14;
      break;
    case rightX < imageWidths[15]:
      rightPosition = 15;
      break;
    case rightX < imageWidths[16]:
      rightPosition = 16;
      break;
    case rightX < imageWidths[17]:
      rightPosition = 17;
      break;
    case rightX < imageWidths[18]:
      rightPosition = 18;
      break;
    case rightX < imageWidths[19]:
      rightPosition = 19;
      break;
    case rightX < imageWidths[20]:
      rightPosition = 20;
      break;
    case rightX < imageWidths[21]:
      rightPosition = 21;
      break;
    default:
      rightPosition = 22;
  }
  setChangePointer(monthData[leftPosition])
  const parentData = $(`.${monthData[leftPosition]}`).parent().parent().data("year")
  switch(parentData){
    case 2023:
    changeYear("2024")
    spChangeYear("2024")
    break
    case 2024:
    changeYear("2023")
    spChangeYear("2023")
    break
  }
});

// PC右矢印クリック後、現在表示している画像から来月の画像までスクロールさせる
$('#news .right-arrow img,.news .right-arrow img').click(function(e) {
  setMoveEventImageToRight()
});

// PC左矢印クリック後、現在表示している画像から先月の画像までスクロールさせる
$('#news .left-arrow img,.news .left-arrow img').click(function(e) {
  setMoveEventImageToLeft()
});

// sp用の画像を動かす処理
$('.news .right-arrow img').on('touchstart', function () {
  setMoveEventImageToRight()
});

// sp用の画像を動かす処理
$('.news .left-arrow img').on('touchstart', function () {
  setMoveEventImageToLeft()
});

$("#2023").on('click', () => {
  changeYear("2024")
  //2023年をクリックすると1月に移動させる
  selectedMonth("jan")
})

$("#2024").on('click', () => {
  changeYear("2023")
  //2024年をクリックすると当月に移動させる
  currentMonth()
})

$("#sp-select-year").on('click', (e) => {
  const year = $(e.currentTarget).data("current-year")
  switch(year){
    case "2023":
      spChangeYear("2024")
      //2023年をクリックすると1月に移動させる
      selectedMonth("jan")
      break
    case "2024":
      spChangeYear("2023")
      //2024年をクリックすると当月に移動させる
      currentMonth()
      break
  }
})

const changeYear = (currentYear) => {
  switch (currentYear) {
    case "2023":
      $('.months-select-next .month').addClass("disp-block")
      $('.pc .months-select .month').addClass("disp-none")
      $('.months-select-next .month').removeClass("disp-none")
      $('.pc .months-select .month').removeClass("disp-block")
      $('.pc .months-select').addClass("news-2023-off")
      $('.pc .months-select').removeClass("news-2023-on")
      $('.months-select-next').removeClass("news-2024-off")
      $('.months-select-next').addClass("news-2024-on")
      break
    case "2024":
      $('.pc .months-select .month').addClass("disp-block")
      $('.months-select-next .month').addClass("disp-none")
      $('.pc .months-select .month').removeClass("disp-none")
      $('.months-select-next .month').removeClass("disp-block")
      $('.pc .months-select').removeClass("news-2023-off")
      $('.months-select-next').removeClass("news-2024-on")
      $('.months-select-next').addClass("news-2024-off")
      $('.pc .months-select').addClass("news-2023-on")
      break
  }
}

const spChangeYear = (currentYear) => {
  switch(currentYear){
    case "2023":
      $(".news .months-select").removeClass("sp-news-2023-bg")
      $(".news .months-select").addClass("sp-news-2024-bg")
      $("#2024-list").addClass("disp-block")
      $("#2024-list").removeClass("disp-none")
      $("#2023-list").addClass("disp-none")
      $("#2023-list").removeClass("disp-block")
      $("#sp-select-year span").text("2023")
      $("#sp-select-year").removeClass("news-2024-img")
      $("#sp-select-year").addClass("news-2023-img")
      $("#sp-select-year").data("currentYear", currentYear)
      break
    case "2024":
      $(".news .months-select").removeClass("sp-news-2024-bg")
      $(".news .months-select").addClass("sp-news-2023-bg")
      $("#2023-list").addClass("disp-block")
      $("#2023-list").removeClass("disp-none")
      $("#2024-list").addClass("disp-none")
      $("#2024-list").removeClass("disp-block")
      $("#sp-select-year span").text("2024")
      $("#sp-select-year").removeClass("news-2023-img")
      $("#sp-select-year").addClass("news-2024-img")
      $("#sp-select-year").data("currentYear", currentYear)
      break
  }
}

const selectedMonth = (month) => {
  $("#scroll-image").animate({
    scrollLeft: getEventImageAddedWidth()[monthDataStr[month] - 1]
  }, 'slow');
}

const currentMonth = () => {
  $('#e-' + monthData[defaultSetMonth])[0].scrollIntoView({
    behavior: "instant",
    block: "nearest",
    inline: "start"
  });
}
